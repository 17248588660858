@use '@angular/material' as mat;
@use 'variables' as *;
@import 'tippy.js/dist/tippy.css';
@import 'overrides';
@import 'utilities';
@include mat.all-component-typographies();
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$declic-primary: mat.define-palette(mat.$blue-palette);
$declic-accent: mat.define-palette(mat.$amber-palette);

// // The warn palette is optional (defaults to red).
$declic-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$declic-theme: mat.define-light-theme(
  (
    color: (
      primary: $declic-primary,
      accent: $declic-accent,
      warn: $declic-warn,
    ),
    typography: mat.define-typography-config(),
    density: -1,
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($declic-theme);

$custom-typography: mat.define-typography-config(
  $font-family: 'Lato, monospace',
);

@include mat.all-component-typographies($custom-typography);

/* You can add global styles to this file, and also import other style files */

html,
body {
  margin: 0;
  font-family: Lato, 'Helvetica Neue', sans-serif;
  background: $background !important;
  height: 100%;
  width: 100%;
}

.full-width {
  width: 100%;
}

.strong {
  font-weight: 600;
}

.declic-emphasis {
  color: #aaa;
  font-weight: 600;
}

.declic-deemphasis {
  color: rgba(0, 0, 0, 0.3);
  font-size: 12px;
}

.deemphasized {
  color: #999;
}

.text-start {
  text-align: start !important;
}

.weight-light {
  font-weight: 300;
}

.text-center {
  text-align: center !important;
}

.self-center {
  align-self: center;
}

.text-end {
  text-align: end;
}

.half-opacity {
  opacity: 0.5;
}

.offset-icon-bottom {
  margin-bottom: 3px !important;
}

.offset-icon-end {
  margin-right: 3px !important;
}

.font-smaller {
  font-size: smaller;
}

.debug {
  background: #f0f0f0;
}

.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.overflow {
  overflow: auto;
}

.clickable {
  transition: ease-in-out 0.3s;
  &:hover {
    transition: ease-in-out 0.3s;
    color: $primary-color;
    cursor: pointer;
  }
}

.declic-item-clickable {
  color: $primary-color;
  opacity: 0.75;
  transition: ease-in-out 0.3s;
  font-weight: bold;
  &:hover {
    cursor: pointer;
    opacity: 1;
    transition: ease-in-out 0.3s;
  }
}

.color-white {
  color: white !important;
}

.declic-main-content {
  padding: 16px;
}

.declic-empty-content {
  padding: 16px;
  min-width: 1000px;
}

.declic-alpha-card {
  padding: 16px;
  background: rgba(255, 255, 255, 0.75);
  border: 2px solid #eee;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.padding-16px {
  padding: 16px;
}

.color-accent-contrast {
  color: $secondary-color-dark;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  span,
  mat-icon,
  .spinner-content {
    @extend .invisible;
  }
}

.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #009de9;
  animation: spinner 1s linear infinite;
}

.justify-center {
  justify-content: center !important;
}

.bg-engie-gradient {
  background: linear-gradient(135deg, #00aaff 0.89%, #23d2b5 94.05%);
}

.fg-engie-gradient {
  background: linear-gradient(135deg, #00aaff 0.89%, #23d2b5 94.05%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.fg-disabled {
  color: #eee !important;
  pointer-events: none;
}

.bg-disabled {
  background-color: #eee !important;
  pointer-events: none;
}

.opacity-disabled {
  opacity: 0.5;
  pointer-events: none;
  transition: ease-in-out 0.3s;
}

.eased {
  transition: ease-in-out 0.3s;
}

.declic-result-section-title {
  background: $engie-gradient;
  background-size: 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 18px;
}

.declic-select-div {
  width: 50%;
}

.declic-select-label {
  font-size: 12px;
  line-height: 16px;
  color: $primary-color;
}

.declic-select {
  border-top: -0.84375em solid transparent;
}

.heartbeat {
  -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
  animation: heartbeat 1.5s ease-in-out infinite both;
}

.invisible {
  visibility: hidden;
}

.readonly {
  pointer-events: none;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-2-22 17:56:18
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation heartbeat
 * ----------------------------------------
 */
@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

.card {
  background: white;
  padding: 16px;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.pointer {
  cursor: pointer;
}

.cursor-help {
  cursor: help;
}

.declic-select {
  border: 1px solid #757575;
  background: white;
  border-radius: 2px;
  padding: 4px 8px;
}

.declic-snack {
  color: black;
}

.declic-snack-ok {
  @extend .declic-snack;
  background-color: #dff6dd;
}

.declic-snack-nok {
  @extend .declic-snack;
  background-color: #fde7e9;
}

.auxiliary-property-hint {
  font-size: 0.8rem;
  margin-top: 8px;
  text-align: right;
  border-right: 4px solid #db3835;
  max-width: 30vw;
  padding-right: 12px;
  font-weight: bold;
}

.unclickable {
  pointer-events: none;
}

.shadow {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
}

.border-grey {
  border-color: #ddd;
}

.selected {
  background-color: rgba($primary-color, 0.1);
  border-color: $primary-color;
  color: $primary-color;
}

.shadow-bottom {
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.1);
}

.shadow-top {
  box-shadow: 0 -2px 2px -2px rgba(0, 0, 0, 0.1);
}
