::ng-deep.mat-mdc-menu-panel {
  max-width: none !important;
}

.mat-expansion-panel {
  box-shadow: none !important;
  background-color: #fafafa !important;
}

h1 {
  font-size: 2rem !important;
}

.mat-mdc-unelevated-button,
.mat-mdc-outlined-button {
  border-radius: 0 !important;
}

.mat-drawer-container {
  background: #fff !important;
}

.mat-mdc-row:nth-child(odd) {
  background: #fff !important;
}

.mat-mdc-row:nth-child(even) {
  background-color: #fefefe !important;
}

.mat-mdc-tab-header {
  border-bottom: unset !important;
}

.loader {
  margin-bottom: unset !important;
}

mat-dialog-container {
  padding: 1.5rem;
}

.mat-mdc-dialog-surface {
  padding: 1.5rem;
}

.nj-table thead tr th {
  text-transform: none !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

td {
  vertical-align: middle !important;
}
