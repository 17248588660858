.color-primary {
  color: $primary-color !important;
}

.bg-white {
  background-color: white !important;
}

.color-danger {
  color: $negative-color !important;
}

.color-grey {
  color: grey !important;
}

.font-mono {
  font-family: monospace !important;
}

.pseudo-disabled {
  pointer-events: none !important;
  color: #aaa !important;
  cursor: not-allowed !important;
}

.clickable {
  color: $primary-color !important;
  cursor: pointer !important;
  font-weight: bold !important;
}

.being-deleted {
  opacity: 0.5;
  color: grey;
  cursor: progress;
}

.tr-err {
  background-color: rgba($negative-color, 0.2) !important;
  color: $negative-color !important;
}

.bg-grey {
  background-color: #f0f0f0 !important;
}
